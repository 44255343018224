import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { I18nNamespaces } from '../../@types/i18next'
import { assertUnreachable } from './types'

export function useLocaleLabel() {
  const { t } = useTranslation('common')

  return (locale: string) => {
    switch (locale) {
      case 'cs':
        return t('locale.cs')
      case 'en':
        return t('locale.en')
      default:
        assertUnreachable(locale as never)
    }
  }
}

export function usePageTranslation(namespaces: (keyof I18nNamespaces)[]) {
  const { i18n, t } = useTranslation(namespaces, {
    bindI18n: 'languageChanged loaded'
  })

  useEffect(() => {
    i18n.reloadResources(i18n.resolvedLanguage, namespaces)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n])

  return t
}
