import { useTranslation } from 'next-i18next'
import { ZodErrorMap } from 'zod'
import { isDate } from './types'

const VALIDATION_ERROR_VARIANTS = ['required', 'email', 'phone']
export type ValidationErrorVariant = (typeof VALIDATION_ERROR_VARIANTS)[number]

function isValidationErrorVariant(
  value: string
): value is ValidationErrorVariant {
  return VALIDATION_ERROR_VARIANTS.includes(value)
}

export const useValidationErrorTranslation = () => {
  const { t } = useTranslation(['common'])

  return (variant: ValidationErrorVariant) => {
    switch (variant) {
      case 'required':
        return t('common:validationError.required')
      case 'email':
        return t('common:validationError.email')
      default:
        return t('common:validationError.default')
    }
  }
}

export function useDefaultZodErrorMap(): ZodErrorMap {
  const validationError = useValidationErrorTranslation()

  return (error, ctx) => {
    if (error.message) {
      return { message: error.message }
    }

    if (error.code === 'invalid_type') {
      return { message: validationError('required') }
    }

    if (error.code === 'invalid_enum_value') {
      if (ctx.data === undefined || ctx.data === null) {
        return { message: validationError('required') }
      }
    }

    if (isValidationErrorVariant(error.code)) {
      return { message: validationError(error.code) }
    }

    return { message: ctx.defaultError }
  }
}

export const useDateTimeValidation = (value?: Date | null) => {
  const { t } = useTranslation(['common'])

  if (!isDate(value)) {
    return t('common:validationError.default')
  } else if (!value) {
    return t('common:validationError.required')
  }
  return null
}
