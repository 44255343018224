import { LINKS } from 'constants/links'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { PageLoader } from './PageLoader'
import { SeoTitle } from './seo/SeoTitle'

interface PageSessionProps {
  allowedGroup: 'user' | 'guest'
  children: JSX.Element
  hideLoadingTitle?: boolean
}

export function PageSession({
  allowedGroup,
  hideLoadingTitle,
  children
}: PageSessionProps) {
  const session = useSession()
  const router = useRouter()
  const { t } = useTranslation(['common'])

  if (session.status === 'loading') {
    return (
      <>
        {hideLoadingTitle ? (
          <DefaultSeo title="" titleTemplate="dotu calendar" />
        ) : (
          <SeoTitle title={t('common:loading')} />
        )}
        <PageLoader />
      </>
    )
  }

  if (session.status === 'unauthenticated' && allowedGroup === 'user') {
    router.push(LINKS.home)
    return null
  } else if (session.status === 'authenticated' && allowedGroup === 'guest') {
    router.push(LINKS.app)
    return null
  }

  return children
}
