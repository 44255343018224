import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'

interface LoaderProps {
  size?: number | string
  timeout?: number
}

export function Loader({ size = 16, timeout = 600 }: LoaderProps) {
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), timeout)
    return () => clearTimeout(timer)
  }, [timeout])

  return isVisible ? <CircularProgress size={size} /> : null
}
