import styled from '@emotion/styled'
import LogoCircle from 'assets/images/logo/LogoCircle.svg'
import { PRIMARY_COLOR } from 'styles/theme'

export const SLogoCircle = styled(LogoCircle)`
  position: absolute;
  top: -20%;
  left: -20%;
  height: 25vh;
  z-index: -1;
  fill: ${PRIMARY_COLOR};
`
