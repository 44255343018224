import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import LogoText from 'assets/images/logo/LogoText.svg'
import { useTranslation } from 'next-i18next'

const now = new Date()

export function LayoutFooter() {
  const { t } = useTranslation(['common'])

  const { typography, breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  return (
    <Box bgcolor="primary.main" paddingY={{ xs: 5, sm: 10 }} textAlign="center">
      <Container>
        <LogoText css={{ fill: '#fff' }} height={isSmallDevice ? 21 : 32} />
        <Typography
          variant="body2"
          marginTop={1}
          color="common.white"
          sx={{ opacity: typography.opacity.high }}
        >
          Copyright © {now.getFullYear()}. {t('common:allRightsReserved')}
        </Typography>
      </Container>
    </Box>
  )
}
