import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'
import { SLogo } from './Layout.styled'
import { LayoutFooter } from './LayoutFooter'

export function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <Box position="relative" overflow="hidden" minHeight="100vh">
        <SLogo />

        <main>{children}</main>
      </Box>

      <LayoutFooter />
    </>
  )
}
