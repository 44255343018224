import { NextSeo, NextSeoProps } from 'next-seo'
import {
  useCanonicalUrl,
  useLanguageAlternatives,
  useNoindex
} from './Seo.utils'

interface SeoTitleProps extends NextSeoProps {
  title: string
  description?: string
  allowQueryParams?: string[]
}

export function SeoTitle({
  description,
  title,
  allowQueryParams,
  ...rest
}: SeoTitleProps) {
  const languageAlternatives = useLanguageAlternatives()
  const canonicalUrl = useCanonicalUrl({ allowQueryParams })
  const noindex = useNoindex()

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        description: description,
        title: title
      }}
      languageAlternates={languageAlternatives}
      canonical={canonicalUrl}
      noindex={noindex}
      {...rest}
    />
  )
}
