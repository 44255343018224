import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import LogoText from 'assets/images/logo/LogoText.svg'

interface LayoutAuthTitleProps {
  title: string
  subtitle: string
  displayLogo?: boolean
}

export function LayoutAuthTitle({
  title,
  subtitle,
  displayLogo
}: LayoutAuthTitleProps) {
  const { palette } = useTheme()

  return (
    <Box marginBottom={4} textAlign="center" marginX="auto">
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        justifyContent="center"
      >
        <Typography
          variant="h4"
          component="h1"
          lineHeight={displayLogo ? 1 : 'inherit'}
          whiteSpace="nowrap"
        >
          {title}
        </Typography>

        {displayLogo && (
          <>
            <Box
              minWidth={{ xs: '6px', sm: '8px' }}
              height={{ xs: '6px', sm: '8px' }}
              bgcolor="primary.main"
              sx={{ borderRadius: '3px' }}
            />

            <LogoText height="1.55rem" style={{ fill: palette.text.primary }} />
          </>
        )}
      </Stack>

      <Typography variant="body2" marginTop={1}>
        {subtitle}
      </Typography>
    </Box>
  )
}
