import { useRouter } from 'next/router'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

export function LayoutAuthLanguageSwitcher() {
  const {
    pathname,
    asPath,
    query,
    push,
    locale: activeLocale,
    locales
  } = useRouter()

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        gap={1}
        py={{ xs: 0.5, sm: 1 }}
        alignItems="center"
      >
        {locales?.map((locale, index) => (
          <>
            <Button
              key={locale}
              disabled={locale === activeLocale}
              size="small"
              sx={{
                minWidth: 0,
                px: 0.5,
                color: 'text.secondary',
                '&.Mui-disabled': {
                  color: 'primary.main'
                }
              }}
              onClick={() =>
                push({ pathname, query }, asPath, {
                  locale
                })
              }
            >
              {locale.toUpperCase()}
            </Button>

            {index < locales.length - 1 && (
              <Box
                minWidth="4px"
                height="4px"
                bgcolor="text.primary"
                sx={{ borderRadius: '3px' }}
              />
            )}
          </>
        ))}
      </Stack>
    </Container>
  )
}
