import Stack from '@mui/material/Stack'
import { Layout } from './layout/Layout'
import { Loader } from './loader/Loader'

export function PageLoader() {
  return (
    <Layout>
      <Stack
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        textAlign="center"
      >
        <Loader size={96} timeout={200} />
      </Stack>
    </Layout>
  )
}
