export const LINKS = {
  home: '/',

  register: '/register',
  registerToken: (token: string) => `/register/${token}`,
  resetPassword: '/reset-password',
  resetPasswordToken: (token: string) => `/reset-password/${token}`,

  app: '/app',
  appParams: (
    startDate: string,
    endDate: string,
    view?: 'agenda' | 'month',
    processId?: string
  ) => {
    let url = `/app?start=${startDate}&end=${endDate}`
    view && (url += `&view=${view}`)
    processId && (url += `#${processId}`)
    return url
  },

  appAccounts: '/app/accounts',

  appSettingsGeneral: '/app/settings/general',
  appSettingsContact: '/app/settings/contact',
  appSettingsSecurity: '/app/settings/security',
  appSettingsLink: '/app/settings/link'
}
